import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import gql from "graphql-tag";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import MediaDescriptionBlock from "../../components/MediaDescription";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { translit, makeUrl, removePreloader, markdownConverter } from "../../helpers";
import { Share } from "../../widgets";
import { PureCarousel } from "../../components/PureReactCarousel";

export const query = graphql`
	query saintsDataQuery($slug: String!) {
		hasura {
			saints(where: { slug: { _eq: $slug } }) {
				title
				page_title_full
				page_title_short
				slug
				main_image
				main_image_preview
				seo
				content_blocks
				type
				ikons_saints {
					ikon {
						type
						slug
						title_full
						title_short
						main_image
						main_image_preview
					}
				}
			}
		}
	}
`;

const FETCH_QUERY = gql`
	query FetchData($location: String!) {
		saints(where: { slug: { _eq: $location } }) {
			title
			page_title_full
			page_title_short
			main_image_preview
			slug
			main_image
			seo
			content_blocks
			type
			ikons_saints {
				ikon {
					type
					slug
					title_full
					title_short
					main_image
					main_image_preview
				}
			}
		}
	}
`;

export default function SaintsPage({ location, data }) {
	const hasuraData = data.hasura;
	const [path] = useState(last(compact(get(location, "pathname", "").split("/"))));
	const [isMount, setIsMount] = useState(false);
	const [saints, setSaints] = useState(get(hasuraData, "saints[0]", {}));

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount && !error) {
			setSaints(get(fetchData, "saints[0]", {}));
		}

		removePreloader(!loading || error);
	}, [loading]);

	const { page_title_full, main_image_preview, main_image, page_title_short, content_blocks, title } = saints;

	const url = makeUrl.saint(saints);
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const ikons_saints = get(saints, "ikons_saints", []);

	const images = ikons_saints.map(item => ({
		path: makeUrl.ikon(get(item, "ikon", {})),
		src: get(item, "ikon.main_image_preview.src", get(item, "ikon.main_image.src", "")),
		title: get(item, "ikon.title_full", get(item, "ikon.title_short", "")),
	}));

	return <Pages entity={saints} url={url} >
		<ContentBlock key={"breadcrumbs"}>
			<div className="container">
				<Breadcrumbs
					currentLabel={`Святые / ${title}`}
				/>
			</div>
		</ContentBlock>
		<ContentBlock key={"main-content"}>
			<div className={"container"}>
				<TextBlock className={"d-flex flex-column"}>
					<PureCarousel
						type={"SlideNews"}
						items={images}
						mediaSize={["100%", 400]}
						slideRatio={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						slideRatioMobile={{
							naturalSlideWidth: 3,
							naturalSlideHeight: 4,
						}}
						params={{
							slidesPerView: 4,
							breakpoints: [
								{
									width: 0,
									slidesPerView: 1,
								},
								{
									width: 768,
									slidesPerView: 2,
								},
								{
									width: 950,
									slidesPerView: 3,
								},
							],
						}}
					/>
				</TextBlock>
				{content_blocks instanceof Array &&
				content_blocks.map(({ title, body }, idx) => {
					return (
						<ContentBlock key={`${translit(title)}-${idx}`}>
							<MediaDescriptionBlock
								title={page_title_full}
								subtitle={title}
								desc={markdownConverter(body)}
								img={get(saints, "main_image.src", "")}
							/>
						</ContentBlock>
					);
				})}
			</div>
		</ContentBlock>
		<div className={"container"}>
			<Share
				url={url}
				pageTitleShort={page_title_short}
				pageTitleFull={page_title_full}
				imageSrc={imageSrc}
			/>
		</div>
	</Pages>;
}

SaintsPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

SaintsPage.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
