import React from "react";
import PropTypes from "prop-types";

import "./MediaDescription.scss";
import { removeEmptyKeys, translit } from "../../helpers/index";
import { TextBlock } from "../Blocks";
import { useThemeContext } from "../Layout/ThemeContext";
import { Image } from "../Media";

export default function MediaDescription({ title = "", img, subtitle, desc = null }) {
	const { theme } = useThemeContext();

	return (
		<div className={"container"}>
			<div className={"row"}>
				<div className={"col-12"}>
					<div className="appeal-block">
						<h2
							className={"appeal-block__title"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h2,
								lineHeight: theme.lineHeight.h2,
								color: theme.color.text,
							})}
						>
							{title}
						</h2>
						<div className="appeal-block__desc">
							{img && (
								<div className={"appeal-block__img"}>
									<Image src={img} alt={translit(title)} style={{ ...theme.image }} />
								</div>
							)}
							<h4
								className={"appeal-block__subtitle"}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.h4,
									lineHeight: theme.lineHeight.h4,
									color: theme.color.text,
								})}
							>
								{subtitle}
							</h4>
							{desc && <TextBlock desc={desc} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

MediaDescription.propTypes = {
	title: PropTypes.string,
	img: PropTypes.string,
	subtitle: PropTypes.string,
	desc: PropTypes.string,
};

MediaDescription.defaultProps = {
	title: "",
	img: "",
	subtitle: "",
	desc: "",
};
